/* global google */
import React, { useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 12.9716, // Default latitude (Bangalore)
  lng: 77.5946, // Default longitude
};

const MapComponent = ({ selectedDate, setEventDates, filters, events }) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const infoWindowRef = useRef(null);

  // Initialize the Google Maps API and map
  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyCgU8ok_12P2PGbSmGKFdRyFyRQF0FNIU8",
      version: "weekly",
      libraries: ["places"],
    });

    loader
      .load()
      .then(() => {
        if (mapRef.current && !mapInstanceRef.current) {
          mapInstanceRef.current = new google.maps.Map(mapRef.current, {
            center,
            zoom: 12,
            mapId: "cff9b68ed09c5011",
          });
          infoWindowRef.current = new google.maps.InfoWindow();
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps:", error);
      });
  }, []);

  // Whenever filters, selectedDate, or events change, update markers
  // Update markers on events change
  useEffect(() => {
    if (!mapInstanceRef.current) return;

    // Clear existing markers
    if (mapInstanceRef.current.markers) {
      mapInstanceRef.current.markers.forEach((marker) => marker.setMap(null));
    }

    // Filter events
    const filteredEvents = events.filter((event) => {
      const now = new Date();
      const eventDate = new Date(event.date);

      const isUpcoming = eventDate >= now;
      const isPast = eventDate < now;

      // Check if event passes the filter conditions
      const passesFilter =
        (filters.showUpcoming && isUpcoming) || (filters.showPast && isPast);

      if (!passesFilter) {
        return false;
      }

      // If a date is selected, restrict events to that exact date.
      if (selectedDate) {
        const sameDay =
          eventDate.getDate() === selectedDate.getDate() &&
          eventDate.getMonth() === selectedDate.getMonth() &&
          eventDate.getFullYear() === selectedDate.getFullYear();
        return sameDay;
      }

      // If no date selected, just return events that match the filters
      return true;
    });

    const markers = filteredEvents.map((event) => {
      const { latitude, longitude } = event.eventHotspot ?? center;

      // Validate lat/long
      if (isNaN(latitude) || isNaN(longitude)) {
        console.error("Invalid lat/long:", latitude, longitude);
        return null;
      }

      const marker = new google.maps.Marker({
        position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
        map: mapInstanceRef.current,
        title: event.event_name,
      });

      marker.addListener("click", () => {
        infoWindowRef.current.setContent(`
          <div style="font-family: Arial, sans-serif; color: #333; max-width: 350px; border: 1px solid #ddd; border-radius: 8px; overflow: hidden; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
            <!-- Hero Image -->
            <div style="width: 100%; height: 150px; overflow: hidden;">
              <img src="${
                event.eventHotspot?.original_image ??
                "https://via.placeholder.com/350x150"
              }" 
                   alt="Event Hero" 
                   style="width: 100%; height: 100%; object-fit: cover;">
            </div>
            
            <!-- Title -->
            <div style="padding: 12px;">
              <h2 style="font-size: 18px; font-weight: bold; margin: 0; color: #029E9B;">
                ${event.eventHotspot?.property_name ?? "No Property Name"}
              </h2>
              
              <!-- Event Details -->
              <div style="margin-top: 10px; color: #555;">
              <p style="margin: 5px 0; font-size: 14px;">
                  <span style="font-weight: bold; color: #029E9B;">🎉</span> 
                  ${event.event_name}
                </p>
                <p style="margin: 0; font-size: 14px;">
                  <span style="font-weight: bold; color: #029E9B;">📅</span> 
                  ${new Date(event.date).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </p>
                <p style="margin: 5px 0 0 0; font-size: 14px;">
                  <span style="font-weight: bold; color: #029E9B;">⏰</span> 
                  ${new Date(
                    `1970-01-01T${event.start_time}Z`
                  ).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })} - 
                  ${new Date(
                    `1970-01-01T${event.end_time}Z`
                  ).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
                <p style="margin: 5px 0 0 0; font-size: 14px;">
                  <span style="font-weight: bold; color: #029E9B;">📝</span> 
                  ${event.description ?? "No description available"}
                </p>
              </div>
      
              <!-- CTA -->
              <a href="${event.eventHotspot?.map_link ?? "#"}" 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 style="display: inline-block; margin-top: 10px; font-size: 14px; color: #FF9519; font-weight: bold; text-decoration: none;">
                Check out the address 
                <img style="display: inline-block; vertical-align: middle;" src="assets/images/Vector-ArrowRight.svg" alt="Arrow Right">
              </a>
            </div>
          </div>
        `);
        infoWindowRef.current.open(mapInstanceRef.current, marker);
      });

      return marker;
    });

    // Remove null markers
    mapInstanceRef.current.markers = markers.filter(
      (marker) => marker !== null
    );
  }, [selectedDate, filters, events]);

  return <div ref={mapRef} style={mapContainerStyle}></div>;
};

export default MapComponent;
