import React from "react";

function Navbar() {
  return (
    <nav className="shadow-md pb-0 pt-4 sm:py-4 border-b bg-[#F6F5F5]">
      <div className="container w-full sm:flex sm:flex-row items-center justify-between sm:px-8 mx-auto">
        {/* Logo */}
        <div className="flex items-center space-x-2">
          <img
            src="https://res.cloudinary.com/delwjbj2a/image/upload/v1732644005/Group_467_s9bz4r.png"
            alt="Retailwise Logo"
            className="h-10"
          />
        </div>

        {/* Navigation Links for Desktop */}
        <div className="hidden sm:flex sm:items-center sm:text-center gap-6">
          <a
            href="https://www.dashboard.retailwise.in"
            className="text-[#474848] hover:text-[#138784] text-xl font-medium"
          >
            Brand Dashboard
          </a>
          <a
            href="/event-calendar"
            className="text-[#474848] hover:text-[#138784] text-xl font-medium"
          >
            Event Calendar
          </a>
          <a
            href="#blogs"
            className="text-[#474848] hover:text-[#138784] text-xl font-medium"
          >
            Blogs
          </a>
        </div>

        {/* Navigation Links for Mobile */}
        <div className="sm:hidden mt-1 px-2 py-2 pb-0">
          <nav className="flex justify-around items-center w-full">
            <a
              href="https://www.dashboard.retailwise.in"
              className="p-2 font-header-font text-[14px] transition text-center wrap duration-300 text-header-text"
            >
              Brand Dashboard
            </a>
            <a
              href="/event-calendar"
              className="p-2 font-header-font text-[14px] text-center transition duration-300 text-header-text"
            >
              Event Calendar
            </a>
            <a
              href="#blogs"
              className="p-2 font-header-font text-[14px] text-center transition duration-300 text-header-text"
            >
              Blogs
            </a>
          </nav>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
