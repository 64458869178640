import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { FaBars, FaTimes } from "react-icons/fa";
import MapComponent from "../components/MapComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const EventPage = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  // eslint-disable-next-line
  const [eventDates, setEventDates] = useState([]);
  const [filters, setFilters] = useState({
    showUpcoming: true,
    showPast: false,
  });
  const [events, setEvents] = useState([]);
  const [isResetActive, setIsResetActive] = useState(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [currentViewDate, setCurrentViewDate] = useState(new Date());

  useEffect(() => {
    const defaultFilters = { showUpcoming: true, showPast: false };
    const isFiltersChanged =
      filters.showUpcoming !== defaultFilters.showUpcoming ||
      filters.showPast !== defaultFilters.showPast;
    const isDateChanged = selectedDate !== null;

    setIsResetActive(isFiltersChanged || isDateChanged);
  }, [filters, selectedDate]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const baseURL =
          process.env.REACT_APP_API_ENDPOINT || "https://api.retailwise.in/api";
        const response = await axios.get(`${baseURL}/events/all`);
        const eventData = response.data.data;
        setEvents(eventData);

        // Extract all event dates
        const dates = eventData.map((event) => new Date(event.date));
        setEventDates(dates);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const now = new Date();
  // Filter for highlight purposes (ignores selectedDate)
  const filteredEventsForHighlight = events.filter((event) => {
    const eventDate = new Date(event.date);
    const isUpcoming = eventDate >= now;
    const isPast = eventDate < now;
    return (filters.showUpcoming && isUpcoming) || (filters.showPast && isPast);
  });

  // Filter for display (respects selectedDate)
  const filteredEventsForDisplay = filteredEventsForHighlight.filter(
    (event) => {
      if (!selectedDate) return true;
      const eventDate = new Date(event.date);
      return (
        eventDate.getDate() === selectedDate.getDate() &&
        eventDate.getMonth() === selectedDate.getMonth() &&
        eventDate.getFullYear() === selectedDate.getFullYear()
      );
    }
  );

  const handleWhatsAppChat = () => {
    const message = encodeURIComponent(
      "Hi, I am interested to know more about the events happening in Bengaluru."
    );
    const whatsappUrl = `https://wa.me/+919916101296?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  const highlightableDates = filteredEventsForHighlight.map(
    (event) => new Date(event.date)
  );

  const isFilteredEventDate = (date) => {
    return highlightableDates.some(
      (d) =>
        d.getDate() === date.getDate() &&
        d.getMonth() === date.getMonth() &&
        d.getFullYear() === date.getFullYear()
    );
  };

  // Helper function to compare days
  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const resetFilters = () => {
    setFilters({ showUpcoming: true, showPast: false });
    setSelectedDate(null);
  };

  return (
    <div className="flex flex-col h-screen w-screen">
      <style jsx global>{`
        .custom-calendar {
          width: 242px;
          margin: 0 auto;
        }
        .selected-date-highlight {
          background-color: #ff9519 !important;
          color: #fff !important;
          border-radius: 50% !important;
        }
        .highlighted-event-date {
          background-color: #029e9b !important;
          color: #fff !important;
          border-radius: 50% !important;
        }
        .today-date-highlight {
          border: 2px solid #029e9b;
          border-radius: 50%;
          color: #029e9b !important;
        }
      `}</style>

      {/* Navbar */}
      <nav className="bg-white shadow-md flex items-center justify-between p-4 md:p-6">
        <div className="flex items-center">
          <button
            className="md:hidden mr-4 focus:outline-none"
            onClick={() => setIsSidebarOpen(true)}
            aria-label="Open sidebar"
          >
            <FaBars className="h-6 w-6 text-gray-700" />
          </button>
          <div className="text-2xl md:text-3xl font-semibold text-black">
            Bangalore <span className="text-[#029E9B]">Flea Market Events</span>
          </div>
        </div>
      </nav>

      {/* Mobile Sidebar */}
      <Dialog
        open={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        className="fixed inset-0 z-50 md:hidden"
      >
        <div className="fixed inset-y-0 left-0 w-3/4 bg-white p-4 shadow-lg overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-[#029E9B]">Filters</h2>
            <button
              onClick={() => setIsSidebarOpen(false)}
              className="focus:outline-none"
              aria-label="Close sidebar"
            >
              <FaTimes className="h-6 w-6 text-gray-700" />
            </button>
          </div>

          {/* Filters */}
          <div className="space-y-6">
            <div className="space-y-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showUpcoming}
                  onChange={() =>
                    setFilters((prev) => ({
                      showUpcoming: !prev.showUpcoming,
                      showPast: prev.showUpcoming ? false : prev.showPast,
                    }))
                  }
                  className="mr-2"
                />
                Upcoming Events
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showPast}
                  onChange={() =>
                    setFilters((prev) => ({
                      showUpcoming: prev.showPast ? false : prev.showUpcoming,
                      showPast: !prev.showPast,
                    }))
                  }
                  className="mr-2"
                />
                Past Events
              </label>
            </div>

            {/* Mobile Date Picker */}
            <div className="space-y-2">
              <button
                onClick={() => setIsDatePickerOpen(true)}
                className="w-full bg-gray-200 py-2 rounded-md text-left px-3 focus:outline-none"
              >
                {selectedDate
                  ? selectedDate.toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })
                  : "Select a date"}
              </button>
            </div>

            {/* Reset Filters */}
            <button
              onClick={() => {
                resetFilters();
                setIsSidebarOpen(false);
              }}
              className="w-full bg-gray-200 text-gray-700 py-2 rounded-3xl hover:bg-gray-300 transition duration-300"
            >
              Reset Filters
            </button>
          </div>
        </div>
      </Dialog>

      {/* Mobile Date Picker Modal */}
      <Dialog
        open={isDatePickerOpen}
        onClose={() => setIsDatePickerOpen(false)}
        className="fixed inset-0 z-50 flex items-center justify-center p-4"
      >
        <div className="bg-white p-4 rounded-lg shadow-lg max-h-full overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Select a Date</h2>
            <button
              onClick={() => setIsDatePickerOpen(false)}
              className="focus:outline-none"
              aria-label="Close date picker"
            >
              <FaTimes className="h-6 w-6 text-gray-700" />
            </button>
          </div>
          <div className="custom-calendar">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              inline
              onMonthChange={(date) => setCurrentViewDate(date)}
              onYearChange={(date) => setCurrentViewDate(date)}
              highlightDates={highlightableDates}
              dayClassName={(date) => {
                const selected = selectedDate && isSameDay(date, selectedDate);
                const eventDay = isFilteredEventDate(date);
                const isToday = isSameDay(date, new Date());
                const isCurrentMonthVisible =
                  date.getMonth() === currentViewDate.getMonth() &&
                  date.getFullYear() === currentViewDate.getFullYear();

                if (selected) {
                  return "selected-date-highlight";
                } else if (isToday && isCurrentMonthVisible) {
                  return "today-date-highlight";
                } else if (eventDay) {
                  return "highlighted-event-date";
                }
                return undefined;
              }}
            />
          </div>
        </div>
      </Dialog>

      {/* Main Content */}
      <div className="flex flex-grow">
        {/* Sidebar (Desktop) */}
        <aside className="hidden md:block w-[310px] bg-gray-100 p-4 shadow-lg overflow-y-auto">
          <div className="border bg-white p-4 space-y-6 h-full flex flex-col">
            {/* Filters row with Reset button */}
            <div className="flex justify-between items-center border-b border-[#029E9B] pb-2">
              <h2 className="text-xl font-bold text-[#029E9B]">Filters</h2>
              <button
                onClick={resetFilters}
                className={`text-sm py-1 px-2 rounded-3xl transition duration-300 ${
                  isResetActive
                    ? "bg-[#029E9B] text-white hover:bg-[#027b81]"
                    : "bg-gray-200 text-gray-500 cursor-not-allowed"
                }`}
                disabled={!isResetActive}
              >
                Reset
              </button>
            </div>

            {/* Schedule Filter */}
            <div className="space-y-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showUpcoming}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      showUpcoming: !prev.showUpcoming,
                    }))
                  }
                  className="mr-2"
                />
                Upcoming Events
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showPast}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      showPast: !prev.showPast,
                    }))
                  }
                  className="mr-2"
                />
                Past Events
              </label>
            </div>

            {/* Date Picker (Desktop) */}
            <div className="flex flex-col items-center justify-center">
              <div className="custom-calendar">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  inline
                  onMonthChange={(date) => setCurrentViewDate(date)}
                  onYearChange={(date) => setCurrentViewDate(date)}
                  highlightDates={highlightableDates}
                  dayClassName={(date) => {
                    const selected =
                      selectedDate && isSameDay(date, selectedDate);
                    const eventDay = isFilteredEventDate(date);
                    const isToday = isSameDay(date, new Date());
                    const isCurrentMonthVisible =
                      date.getMonth() === currentViewDate.getMonth() &&
                      date.getFullYear() === currentViewDate.getFullYear();

                    if (selected) {
                      return "selected-date-highlight";
                    } else if (isToday && isCurrentMonthVisible) {
                      return "today-date-highlight";
                    } else if (eventDay) {
                      return "highlighted-event-date";
                    }
                    return undefined;
                  }}
                />
              </div>
            </div>

            {/* Contact Us Button in place of old Reset Filters button */}
            <div className="mt-auto">
              <button
                onClick={handleWhatsAppChat}
                className="w-full bg-gray-200 hover:bg-gray-100 border transition-all duration-300 rounded-full p-2 flex items-center justify-center"
              >
                <span className="font-semibold text-gray-700">Contact Us</span>
                <img
                  src="/assets/images/Hero/whatsapp_icon.svg"
                  alt="WhatsApp"
                  className="w-6 h-6 ml-4"
                />
              </button>
            </div>
          </div>
        </aside>

        {/* Map Section */}
        <div className="flex-1 p-4 relative">
          <MapComponent
            selectedDate={selectedDate}
            setEventDates={setEventDates}
            filters={filters}
            events={filteredEventsForDisplay}
          />
        </div>
      </div>
    </div>
  );
};

export default EventPage;
